.smart-toggle .border-0 {
    border-width: 0 !important;
}

.smart-toggle .border-1 {
    border-width: 1px !important;
}

.smart-toggle .border-2 {
    border-width: 2px !important;
}

.smart-toggle .border-3 {
    border-width: 3px !important;
}

.smart-toggle .border-4 {
    border-width: 4px !important;
}

.smart-toggle .border-5 {
    border-width: 5px !important;
}

.selectable {
    cursor: pointer;
}

.smart-toggle .disabled {
    opacity: 0.4
}

.smart-toggle {
    min-width: 3rem;
}