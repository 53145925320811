.smart-floating-info {
    display: inline-block !important;
    width: 25% !important;
    margin: auto;
    margin-bottom: 10px;

    -webkit-box-shadow: 9px 9px 41px -12px rgba(0,0,0,0.6);
    -moz-box-shadow: 9px 9px 41px -12px rgba(0,0,0,0.6);
    box-shadow: 9px 9px 41px -12px rgba(0,0,0,0.6);
}

.selectable {
    cursor: pointer;
}