.file {
    border: 1px solid #DBDADD;
    border-radius: 3rem;
    margin: 0.2rem 0.2rem 0.2rem 0;
    padding: 0.2rem 0.3rem;
    background: #DBDADD;
}

.file.errors {
    background: #ecbcbc;
}