.invalid .smart-typeahead.validation-highlight input.rbt-input-main  {
    border-color: #dc3545 !important;
}
.invalid .smart-typeahead.validation-highlight .form-control:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 4px 5px 0 rgba(220, 53, 69, 0.14), 0 1px 10px 0 rgba(220, 53, 69, 0.12), 0 2px 4px -1px rgba(220, 53, 69, 0.2) !important;
}

.smart-typeahead.highlight input.rbt-input-main{
    border-bottom: 3px solid #60b0e3;
}