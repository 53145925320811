@import "~react-datepicker/dist/react-datepicker.css";
/**
 DATEPICKER
 */
.react-datepicker {
    display: flex;
    min-width: 219px;
}

.react-datepicker-wrapper{
    display: block;
}

.cem-datepicker .input-group-append .btn {
    margin: 0 !important;
}

.react-datepicker-wrapper .form-control:disabled, .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
}

.react-datepicker-popper {
    z-index: 5;
}

.react-datepicker-wrapper input:focus {
    /* remove focus shadow: */
    box-shadow: 0 0 0rem;
}

/**
IE fuckups go here
 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .react-datepicker-wrapper{
        display: -ms-flexbox;
    }

    .datepicker-wrapper{
        flex: 1 1;
    }
}

.invalid .smart-datepicker.validation-highlight {
    border: 1px solid #dc3545 !important;
}
.invalid .smart-datepicker.form-control:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 4px 5px 0 rgba(220, 53, 69, 0.14), 0 1px 10px 0 rgba(220, 53, 69, 0.12), 0 2px 4px -1px rgba(220, 53, 69, 0.2) !important;
}

.smart-datepicker.highlight {
    border-bottom: 3px solid #60b0e3;
}