.smartmultiple .smartmultiple-input-wrapper {
    border-radius: 0.5rem;
    background: #fff;
    z-index: 20;
}

.invalid .smartmultiple.validation-highlight {
    border-radius: 0.5rem;
    border: 1px solid #dc3545 !important;
}
.invalid .smartmultiple.form-control:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 4px 5px 0 rgba(220, 53, 69, 0.14), 0 1px 10px 0 rgba(220, 53, 69, 0.12), 0 2px 4px -1px rgba(220, 53, 69, 0.2) !important;
}

.smartmultiple.highlight {
    border-bottom: 3px solid #60b0e3;
}

.smartmultiple .smartmultiple-input-wrapper.open {
    border-radius: 0.5rem 0.5rem 0 0;
    background: #fff;
    z-index: 20;
}

.smartmultiple .smartmultiple-input-wrapper.disabled {
    opacity: 1;
    background: #e9ecef;
}

.smartmultiple .smartmultiple-suggestions {
    position: absolute;
    z-index: 20;
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: white;
}

.smartmultiple .smartmultiple-input-wrapper.form-control {
    min-height: calc(1.5em + 1rem + 2px);
    height: auto;
}

.overlay {
    z-index: 10;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #aaa;
    opacity: 0.5;
}