/* LOADING LAYER */
.loading-layer .background{
    background-color: rgba(255,255,255,0.7);
    z-index: 9999;
    height: 100%;
    width: 100%;
    position: absolute;
}

.loading-layer {
    position: relative;
}

.loading-layer.full {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.loading-layer .symbols{
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    text-align: center;
}